import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-500.css';
import '@fontsource/inter/latin-700.css';
import './src/styles/global.css';

import React from 'react';
import RootElement from './src/lib/tan-stack/RootElement';

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};
